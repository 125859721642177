<script setup>
    import { onMounted, ref, watch } from 'vue'
    import { updateBreakpoints, useMq } from "vue3-mq";
    import { useI18n } from 'vue-i18n';

    const { locale } = useI18n();
    let imageUrl = ref(new URL(`/public/assets/${locale.value}/home/cruise/journey_mobile.png`, import.meta.url).href)
    watch(locale, () =>{
        imageUrl.value = new URL(`/public/assets/${locale.value}/home/cruise/journey_mobile.png`, import.meta.url).href;
    })

    const mq = useMq();
    onMounted(() => {
        updateBreakpoints({
            breakpoints: {
                mobile: 600,
                desktop: 1000
            }
        })
    });
</script>
<template>
    <section class="home_cruise">
        <div class="w-100">
            <div class="wrapper">
                <div class="title">
                    {{ $t('home_cruise.title') }}
                    <span class="title primary-text">
                        {{ $t('home_cruise.title_primary') }}
                    </span>
                </div>
                <div class="subtitle">
                    {{ $t('home_cruise.subtitle') }}
                </div>
            </div>
            <div class="inner-wrapper-journey-desktop" v-if="mq.desktop">
                <div class="cruise-lines  cruise-lines-journey container-fluid">
                    <div class="cruise-inner">
                        <img src="/assets/es/home/cruise/journey.svg" />
                    </div>
                </div>
                <div class="cruise-lines inner-cruise container-fluid">
                    <div class="wrapper-line">
                        <div class="cruise-line">
                            <div class="liner upper-line">
                                <div class="upper-image"
                                    data-aos="zoom-out" 
                                    data-aos-easing="ease-in-back"
                                    data-aos-duration="700">
                                    <img src="/assets/es/home/cruise/cruise_1.png" loading="lazy" />
                                </div>
                                <div class="upper-text"
                                data-aos="fade" 
                                data-aos-easing="ease-in-back"
                                data-aos-duration="700">
                                    <div class="primary-text">
                                        01.
                                    </div>
                                    <div class="title">
                                        <span>
                                            {{ $t('home_cruise.tag_1_bold') }}
                                        </span>
                                        {{ $t('home_cruise.tag_1') }}
                                    </div>
                                </div>
                            </div>
                            <div class="liner down-line custom-top-padding-double">
                                <div class="upper-text"
                                data-aos="fade" 
                                    data-aos-easing="ease-in-back"
                                    data-aos-delay="400"
                                    data-aos-duration="700">
                                    <div class="primary-text">
                                        02.
                                    </div>
                                    <div class="title pb-3">
                                        <span>
                                            {{ $t('home_cruise.tag_2_bold') }}
                                        </span>
                                        {{ $t('home_cruise.tag_2') }}
                                    </div>
                                </div>
                                <div class="upper-image"
                                    data-aos="zoom-out" 
                                    data-aos-easing="ease-in-back"
                                    data-aos-delay="300"
                                    data-aos-duration="700">
                                    <img src="/assets/es/home/cruise/cruise_2.png" loading="lazy" />
                                </div>
                            </div>
                            <div class="liner upper-line">
                                <div class="upper-image"
                                    data-aos="zoom-out" 
                                    data-aos-easing="ease-in-back"
                                    data-aos-delay="800"
                                    data-aos-duration="700">
                                    <img src="/assets/es/home/cruise/cruise_3.png" loading="lazy" />
                                </div>
                                <div class="upper-text"
                                data-aos="fade" 
                                    data-aos-easing="ease-in-back"
                                    data-aos-delay="700"
                                    data-aos-duration="700">
                                    <div class="primary-text">
                                        03.
                                    </div>
                                    <div class="title">
                                        <span>
                                            {{ $t('home_cruise.tag_3_bold') }}
                                        </span>
                                        {{ $t('home_cruise.tag_3') }}
                                    </div>
                                </div>
                            </div>
                            <div class="liner down-line custom-top-padding">
                                <div class="upper-text"
                                data-aos="fade" 
                                data-aos-easing="ease-in-back"
                                data-aos-delay="1100"
                                data-aos-duration="700">
                                    <div class="primary-text">
                                        04.
                                    </div>
                                    <div class="title">
                                        <span>
                                            {{ $t('home_cruise.tag_4_bold') }}
                                        </span>
                                        {{ $t('home_cruise.tag_4') }}
                                    </div>
                                </div>
                                <div class="upper-image"
                                    data-aos="zoom-out" 
                                    data-aos-easing="ease-in-back"
                                    data-aos-delay="1200"
                                    data-aos-duration="700">
                                    <img src="/assets/es/home/cruise/cruise_4.png" loading="lazy" />
                                </div>
                            </div>
                            <div class="liner upper-line cruise-fifth-block">
                                <div class="upper-image"
                                    data-aos="zoom-out" 
                                    data-aos-easing="ease-in-back"
                                    data-aos-delay="1600"
                                    data-aos-duration="700">
                                    <img src="/assets/es/home/cruise/cruise_5.png" loading="lazy" />
                                </div>
                                <div class="upper-text"
                                data-aos="fade" 
                                    data-aos-easing="ease-in-back"
                                    data-aos-delay="1500"
                                    data-aos-duration="700">
                                    <div class="primary-text">
                                        05.
                                    </div>
                                    <div class="title">
                                        <span>
                                            {{ $t('home_cruise.tag_5_bold') }}
                                        </span>
                                        {{ $t('home_cruise.tag_5') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="inner-wrapper-journey-mobile" v-else>
                <div class="home-journey-mobile" data-aos="zoom-in">
                    <img :src="imageUrl" />
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.home_cruise {
    padding-top: 125px;
    padding-bottom: 150px;
}
.wrapper {
    
    text-align: center;
    .title {
        font-size: 40px;
        font-family: 'CircularStd-Black';
        letter-spacing: -2px;
    }
    .subtitle {
        font-size: 16px;
    }
}
.inner-wrapper-journey-desktop {
    padding-top: 40px; 
}
.cruise-lines-journey {
    position: relative;
}
.cruise-lines {
    max-width: 1250px; 
    letter-spacing: -0.95px;
    .cruise-inner {
        width: 1250px;
        position: absolute;
        top: 90px;
        left: 1px;
        z-index: 1;
    }
    .liner {
        flex: 1 1 0;
        img {
            width: 100%;
        }
        
    }
    
    .wrapper-line {
        .cruise-line {
            display: flex;
            width: 100%;
            .upper-line, .down-line {
                padding: 4px 30px 0 30px;
                .primary-text {
                    font-size: 40px;
                    font-family: 'CircularStd-Bold';
                }
                &.cruise-fifth-block {
                    padding-right: 40px;
                }
                span {
                    font-family: 'CircularStd-Bold';
                }
            }
            
            .upper-line {
                padding-top: 124px;
            }
            .custom-top-padding {
                padding-top: 92px;
                padding-left: 20px;
                .upper-text {
                    padding-bottom: 20px;
                }
            }
            .custom-top-padding-double {
                padding-top: 70px;
            }
        }
    }
}
.inner-wrapper-journey-mobile {
    display: none;
}
.title.pb-3 {
    width: 103%;
}
@media screen and (max-width: 1450px) {
    .cruise-lines .cruise-inner {
      width: 100%;
      position: absolute;
      top: 114px;
      left: 8px;
      z-index: 1;
    }
}

@media (max-width: 1000px) {
    .inner-wrapper-journey-desktop {
        display: none;
    }
    .inner-wrapper-journey-mobile{
        display: flex;
        justify-content: center;
        img {
            width: 100%;
        }
    }
    .wrapper {
        .title {
            font-size: 2rem;
            padding: 0 45px 50px;
            line-height: 2.1rem;
            span {
                padding: 0;
            }
        }
        
        .subtitle {
            display: none;
        }
    }
    .home_cruise {
        padding-top: 60px;
        padding-bottom: 80px;
    }
}

</style>