<script setup>
import { useI18n } from 'vue-i18n';
import { ref, watch } from 'vue'

const { locale } = useI18n();
let location = ref(locale.value);

watch(locale, () => {
    location = locale.value;
});

</script>
<template>
    <section class="home-try-car">
        <div class="wrapper">
            <div class="title">
                {{ $t('home_try.title') }}
            </div>
            <div class="wrapper-btn">
                <div class="btn-wrapper">
                    <router-link :to="`/${location}/contact`" class="custom-btn">
                        {{ $t('home_try.request_demo') }}
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.home-try-car {
    padding-top: 50px;
    padding-bottom: 50px;
    .wrapper {
        width: 100%;
        text-align: center;
        .title {
            font-size: 30px;
            font-family: 'CircularStd-Black';
            letter-spacing: -1.5px;
            padding-bottom: 25px;
        }
    }
    .btn-wrapper {
        display: flex;
        justify-content: center;
        a {
            font-size: 18px;
            text-decoration: none;
            display: block;
        }
    }
}
@media (max-width: 1000px) {
    .home-try-car {
        padding-top: 50px;
        padding-bottom: 60px;
        .wrapper {
            .title {
                font-size: 2rem;
                padding: 0 80px 30px;
                line-height: 2rem;
            }
        }
    }
}
</style>